import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import MetaTags from '@objects/metatags'
import Container from '@objects/container'
import Button from '@objects/button'
import Headline from '@objects/headline'

function RootIndex({ pageContext }) {
  const intl = useIntl()
  return (
    <Container className="mt-8 mb-10 py-6" nopadding>
      <MetaTags locale={pageContext.locale} meta={{ noIndex: true }} />
      <Headline
        level={1}
        className="mb-8"
        ariaLabel={intl.formatMessage({ id: 'page.404.headline' })}
      >
        <FormattedMessage id="page.404.headline" />
      </Headline>
      <Headline
        level={4}
        className="mb-12"
        ariaLabel={intl.formatMessage({ id: 'page.404.copy' })}
      >
        <FormattedMessage id="page.404.copy" />
      </Headline>
      <Button className="button text-size-medium yellow" to="/">
        <FormattedMessage id="page.404.cta" />
      </Button>
    </Container>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default RootIndex
